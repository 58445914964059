import React from 'react';
import './Spacer.css'

class Spacer extends React.Component {
    render() {
        return (
            <div className = "spacer" ></div>
        );
    }
  }


export default Spacer;