import React from 'react';
import './Line.css'

class Line extends React.Component {
    render() {
        return (
            <hr className = "line" />
        );
    }
  }


export default Line;